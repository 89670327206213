import React from 'react';
import Article from '../../components/article/Article';
import { am, moto, yammie, rider, yamblu } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">New technology advancements in the automotive industry. Get informed.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={am} date="Oct 7, 2023" text="New auto features and it's functionalities" />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={moto} date="Oct 7, 2023" text="New auto features and it's functionalities" />
        <Article imgUrl={yammie} date="Oct 7, 2023" text="New auto features and it's functionalities" />
        <Article imgUrl={rider} date="Oct 7, 2023" text="New auto features and it's functionalities" />
        <Article imgUrl={yamblu} date="Oct 7, 2023" text="New auto features and it's functionalities" />
      </div>
    </div>
  </div>
);

export default Blog;
