import React from 'react';
import { mb, bmw, yamaha, kawa, ducati } from './imports';
import './brand.css';

const Brand = () => (

  <div className="gpt3__brand section__padding">
    <div>
      <img src={mb} />
    </div>
    <div>
      <img src={bmw} />
    </div>
    <div>
      <img src={yamaha} />
    </div>
    <div>
      <img src={kawa} />
    </div>
    <div>
      <img src={ducati} />
    </div>
  </div>
);

export default Brand;
