import React from 'react';
import logo from '../../assets/logo-car.webp';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Do not hesitate to contact us.</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p>Contact Us</p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={logo} alt="gpt3_logo" />
        <p>Tirana, Albania</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Home</p>
        <p>Social Media</p>
        <p>Fleet</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>Tirana, Albania</p>
        <p>355 xxxxxxxxx</p>
        <p>info@example.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023 Car Rental. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
