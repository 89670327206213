import React from 'react';
import people from '../../assets/people.png';
import car from '../../assets/image-car.webp';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Drive Your Dreams: Premium Car Rentals Await You</h1>
      <p>Discover the ultimate in car rental experiences with our exclusive fleet of vehicles. Whether youre planning a road trip a business excursion or just want to indulge in a luxurious ride our meticulously maintained cars are ready to take you on an unforgettable journey.</p>

      <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get Started</button>
      </div>

      <div className="gpt3__header-content__people">
        <img src={people} />
        <p>Trusted by more than 800 customers in last 3 months</p>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={car} />
    </div>
  </div>
);

export default Header;
