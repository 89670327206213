import React from 'react';
import phone from '../../assets/map-phone.webp';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility " id="about-us">
    <div className="gpt3__possibility-image">
      <img src={phone} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content section__padding">
      <h4>About Us</h4>
      <h1 className="gradient__text">Your enjoyment <br /> our priority.</h1>
      <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p>
      <h4>Find Out More...</h4>
    </div>
  </div>
);

export default Possibility;
