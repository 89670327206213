import React from 'react';
import './partner.css';

const Partner = () => (

  <div className="partner-title gradient-text">
    <h1 className="gradient-text">Our Partner</h1>
  </div>
);

export default Partner;
