import React from 'react';
import Feature from '../../components/feature/Feature';
import './services.css';

const Services = () => (
  <div className="gpt3__whatgpt3 section__margin" id="services">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Car Rental" text="Check the entire package deals we offer so we can help you get the best of the experince." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Get your service with the best packages we offer.</h1>
      <p>Explore the Catalog</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Standard" text="Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
      <Feature title="Advanced" text="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. " />
      <Feature title="Premium" text="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. " />
    </div>
  </div>
);

export default Services;
